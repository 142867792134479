import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {GatsbyImage, getImage, ImageDataLike} from 'gatsby-plugin-image';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { SectionTitle, ImageSharpFluid } from 'helpers/definitions';

import * as Styled from './styles';

interface Certification {
  node: {
    frontmatter: {
      category: string;
      certName: string;
      issueDate: string;
      url: string;
      badge: {
        childImageSharp: {
          fluid: ImageSharpFluid;
        };
      };
    };
  };
}

const Certifications: React.FC = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "certifications section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "certifications" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              certName
              issueDate
              url
              badge {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);

  const sectionTitle: SectionTitle = markdownRemark.frontmatter;
  const certifications: Certification[] = allMarkdownRemark.edges;
  const certContent = (cert: Certification) => cert.node.frontmatter

  return (
      <Container section>
        <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />
        {certifications
            .sort((c1, c2) => {
              const name1 = certContent(c1).certName.toLocaleLowerCase()
              const name2 = certContent(c2).certName.toLocaleLowerCase()
              return name1.localeCompare(name2)
            })
            .map(cert => {
              const {badge, certName, url} = certContent(cert)
              const image = getImage(badge as unknown as ImageDataLike)
              return (
                  <Styled.ImageLink to={url} key={certName}>
                    <Styled.Image key={certName}>
                      {image && <GatsbyImage image={image} alt={certName} />}
                    </Styled.Image>
                  </Styled.ImageLink>
              );
            })}
      </Container>
  );
};

export default Certifications;
