import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Education from 'components/Education';
import Skills from 'components/Skills';
import Certifications from 'components/Certifications';

const ProfilePage: React.FC = () => (
  <Layout>
    <SEO title="Profile" />
    <Certifications />
    {/*<hr />
    <Skills />*/}
    <hr />
    <Education />
  </Layout>
);

export default ProfilePage;
